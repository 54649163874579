$(() => {
  $('[data-popupClose]').click((e) => {
    $(e.target).parent('.popup').hide();
  });

  $('[data-slide1]').click(() => {
    $('.popup.firstPop').show();
  });

  $('[data-slide2]').click(() => {
    $('.popup.firstPop').hide();
    $('.popup.secondPop').show();
  });

  $(window).keydown((e) => {
    if (e.which === 27) {
      $('.popup').hide();
    }
  });
});
