$(() => {
  window.Util.timeValidation = false;
  const currentTime = new Date();
  const _y = currentTime.getFullYear();
  const _m = currentTime.getMonth();
  const _d = currentTime.getDate();
  const timeIpt = $('[date-ipt-date-picker]');
  const calendarImg = $('[data-calendar-img]');

  calendarImg.click((e) => {
    $(e.target).prev('.common_form-hidden-ipt').focus();
  });

  function checkDate(year, month, date) {
    const $year = parseInt(year, 10);
    const $month = parseInt(month, 10);
    const $date = parseInt(date, 10);
    let rs = true;
    const monthFor31days = [1, 3, 5, 7, 8, 10, 12];
    if ($date < 1 || $month < 1 || $month > 12 || $year < 1) {
      rs = false;
      return rs;
    }
    if (monthFor31days.indexOf($month) > -1) {
      if ($date > 31) {
        rs = false;
      }
    } else if ($month !== 2) {
      if ($date > 30) {
        rs = false;
      }
    } else if ($year % 4 === 0) {
      if ($date > 29) {
        rs = false;
      }
    } else if ($date > 28) {
      rs = false;
    }
    return rs;
  }

  function checkIpt(ipt) {
    const timeArr = ipt.val().split('-');
    const timeLine = new Date(timeArr[2], timeArr[1], timeArr[0]).getTime();
    const currentTimeLine = new Date(_y, _m + 1, _d).getTime();
    const flag = ipt.hasClass('birthDay') ?
      ((currentTimeLine - timeLine) / (24 * 3600 * 1000 * 365)) >= 18
      && ((currentTimeLine - timeLine) / (24 * 3600 * 1000 * 365)) < 90 :
      ipt.hasClass('endTime') ? new Date(
        $('#startTime').val().split('-')[2],
        $('#startTime').val().split('-')[1],
        $('#startTime').val().split('-')[0],
      )
        <= timeLine :
        currentTimeLine <= timeLine;
    window.Util.timeValidation = (ipt.val().length === 10)
      && flag
      && checkDate(timeArr[2], timeArr[1], timeArr[0]);
    if (window.Util.timeValidation) {
      ipt.datetimepicker({
        value: ipt.val(),
      });
      ipt.parents('.common_sections').removeClass('form_error');
    } else {
      ipt.parents('.common_sections').addClass('form_error');
    }
  }

  function forMate(time) {
    const date = time.getDate() > 9 ? time.getDate() : `0${time.getDate()}`;
    const month = time.getMonth() >= 9 ? time.getMonth() + 1 : `0${time.getMonth() + 1}`;
    const year = time.getFullYear();
    return [date, month, year];
  }

  function fillIpt(ipt, time) {
    ipt.val(`${time[0]}-${time[1]}-${time[2]}`);
    const mySmallIpt = ipt.parents('.formModel__likeIpt').find('.common_form-ipt');
    $(mySmallIpt[0]).val(time[0]);
    $(mySmallIpt[1]).val(time[1]);
    $(mySmallIpt[2]).val(time[2]);
  }

  function addForTwo(timer) {
    const arr = timer.split('-');
    return new Date(arr[0], arr[1] - 1, arr[2]).getTime() + (3600 * 1000 * 48);
  }

  function toInputField(ipt) {
    const mySmallIpt = ipt.parents('.formModel__likeIpt').find('.common_form-ipt');
    mySmallIpt.keydown((e) => {
      const eTarget = $(e.target);
      eTarget.val(eTarget.val().replace(/[^\d-]/g, ''));
      if (!e.shiftKey && e.which !== 9) {
        if (e.which === 8) {
          if ($(mySmallIpt[2]).val().length === 0) {
            $(mySmallIpt[1]).focus();
          }
          if ($(mySmallIpt[1]).val().length === 0) {
            $(mySmallIpt[0]).focus();
          }
        } else {
          if ($(mySmallIpt[0]).val().length === 2 && eTarget.is(mySmallIpt[0])
            && eTarget.get(0).selectionStart === 2) {
            $(mySmallIpt[1]).focus();
          }
          if ($(mySmallIpt[1]).val().length === 2 && eTarget.is(mySmallIpt[1])
            && eTarget.get(0).selectionStart === 2) {
            $(mySmallIpt[2]).focus();
          }
        }
      }
    }).blur(() => {
      ipt.val(`${$(mySmallIpt[0]).val()}-${$(mySmallIpt[1]).val()}-${$(mySmallIpt[2]).val()}`);
      if (ipt.hasClass('startTime')) {
        const inputEndTime = addForTwo(ipt.val().split('-').reverse().join('-'));
        fillIpt($('#endTime'), forMate(new Date(inputEndTime)));
      }
      checkIpt(ipt);
    });
  }

  const birthDayMax = new Date(_y - 18, _m, _d - 1);
  const birthDayMin = new Date(_y - 90, _m, _d - 1);
  const otherMax = new Date(_y + 5, _m, _d);
  const startDayMin = new Date(_y, _m, _d + 1);
  const endDayMin = new Date(addForTwo(forMate(startDayMin).reverse().join('-')));
  const defaultBirthDay = new Date().setFullYear(1960, 1, 1);

  fillIpt($('#startTime'), forMate(startDayMin));
  fillIpt($('#endTime'), forMate(endDayMin));

  timeIpt.each((index, el) => {
    const isBirthDayIpt = $(el).hasClass('birthDay');
    const isStartTime = $(el).hasClass('startTime');
    const timeObj = {
      format: 'd-m-Y',
      timepicker: false,
      defaultSelect: false,
      yearStart: 1920,
      enterLikeTab: true,
      maxDate: isBirthDayIpt ? birthDayMax : otherMax,
      minDate: isBirthDayIpt ? birthDayMin : startDayMin,
      defaultDate: isBirthDayIpt ? defaultBirthDay : isStartTime ? startDayMin : endDayMin,
      scrollMonth: false,
      validateOnBlur: false,
      onSelectDate: (ct) => {
        const timeResult = forMate(ct);
        fillIpt($(el), timeResult);
        checkIpt($(el));

        if ($(el).hasClass('startTime')) {
          const endTime = addForTwo(`${timeResult[2]}-${timeResult[1]}-${timeResult[0]}`);
          const endTimeIpt = $('#endTime');
          fillIpt(endTimeIpt, forMate(new Date(endTime)));
          endTimeIpt.datetimepicker({
            minDate: new Date(timeResult[2], timeResult[1] - 1, timeResult[0]),
            defaultDate: endTime,
          });
        }
      },
    };

    $(el).datetimepicker(timeObj);
    toInputField($(el));

    $(el).blur(() => {
      checkIpt($(el));
    });
  });
});
