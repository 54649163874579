$(() => {
  const currentLocaleIso2 = $("[data-iso2]").val()
    ? $("[data-iso2]").val().toLocaleUpperCase()
    : $("[data-iso2]").val();
  let currentLocaleIso3 = $("[data-iso3]").val();
  const originIso3 = currentLocaleIso3;
  const dealerIpt = document.getElementById("searchTextField");
  const backEndUrl = "/api/GoogleApi/GoogleMapApi";
  const mapInfoListBox = $("[data-map-info-list-box]");
  const mapContainer = $("[data-map-container]");
  const loader = $(".formModel_loader");
  const lcontainer = $(".js-formModel-location-container");
  const dataDealerIdIpt = $("[data-dealer-id]");
  const dataDealerJsonIpt = $("[data-dealer-json]");
  const locationLogo = $(".formModel_search_logo");
  let markers = [];
  let allDealerInfo = [];
  let dealerLocations = [];
  const isEMEA = !$("html").hasClass("en_GB");
  const showMapBtn = $("[data-show-map]");
  showMapBtn.click(() => {
    const parent = showMapBtn.parents(".googleMap__dealerBox");
    parent.addClass("dn");
    $(".formModel-location").show();
  });

  // locationLogo

  locationLogo.on("click", (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.geolocation
      ? navigator.geolocation.getCurrentPosition(getSuccess, getError)
      : console.log("unsupported");
  });

  let map;

  mapContainer.parent(".formModel_row").addClass("formModel_map_container");

  // to make some marks
  /* eslint-disable */
  const google = window.google;
  /* eslint-enable */
  if (!window.google) {
    return;
  }
  let MarkerWithLabel = require("markerwithlabel")(google.maps);
  function makeNewDealerInfo(data) {
    const dealerJson =
      autocompleteLatLngdata.clickedModelCode != "ELW"
        ? {
            dealerAddress: data.address,
            dealerName: data.name,
            dealerPhone: data.phone,
            dealerWebsite: data.website || "",
            dealerEmail: data.programCodes.TR
              ? data.programCodes.TR.email1
                ? data.programCodes.TR.email1
                : data.programCodes.TR.email2
              : "",
            dealerLat: data.position.lat,
            dealerLng: data.position.lng,
          }
        : {
            dealerAddress: {
              city: data.contact.city,
              country: data.contact.country,
              streetAddress: data.contact.address
                ? [data.contact.address1, data.contact.address2]
                : [data.contact.address1],
              zipPost: data.contact.postalCode,
            },
            dealerName: data.name,
            dealerPhone: data.contact.phoneNumber,
            dealerWebsite: data.website || "",
            dealerEmail: data.testRideInfo.email1 || "",
            dealerLat: data.contact.latitude,
            dealerLng: data.contact.longitude,
          };
    return JSON.stringify(dealerJson);
  }

  function setIptVal(index) {
    dataDealerIdIpt.val(allDealerInfo[index].id);
    dataDealerJsonIpt.val(makeNewDealerInfo(allDealerInfo[index]));
  }

  // const markerIconImgUrl = [`${CDNsource}/images/Map-Pin.png`, `${CDNsource}/images/Map-Pin-2.png`];

  const pointX = 17;
  const pointY = 18;
  const CDNsource = "../dist-rebuild";
  const markerIconImgUrl = [
    {
      url: CDNsource + "/images/Map-Pin-2.png",
      labelOrigin: new google.maps.Point(
        parseFloat(pointX),
        parseFloat(pointY)
      ),
    },
    {
      url: CDNsource + "/images/Map-Pin.png",
      labelOrigin: new google.maps.Point(
        parseFloat(pointX),
        parseFloat(pointY)
      ),
    },
    {
      url: CDNsource + "/images/Map-Pin.png",
      labelOrigin: new google.maps.Point(
        parseFloat(pointX),
        parseFloat(pointY)
      ),
    },
  ];

  function changeMarkerImg(idx) {
    /* eslint-disable */
    markers.map((m) => {
      m.setIcon(markerIconImgUrl[0]);
    });
    /* eslint-enable */
    if (idx >= 0) {
      markers[idx].setIcon(markerIconImgUrl[1]);
    }
  }
  function makeMarks(locations) {
    const labels = ["1", "2", "3"];
    markers = locations.map(function (location, i) {
      return new google.maps.Marker({
        position: location,
        icon: markerIconImgUrl[i],
        label: {
          text: labels[i],
          color: "black",
          labelClass: "myMarks",
          labelAnchor: new google.maps.Point(0, 0),
        },
        map: map,
      });
    });

    markers.map((marker, i) =>
      marker.addListener("click", () => {
        changeMarkerImg(i);
        map.panTo(markers[i].position);
        const listBox = $(".googleMap__info-list");
        listBox.removeClass("active");
        $(listBox[i]).addClass("active");
        setIptVal(i);
      })
    );
    setTimeout(() => {
      map.panTo(markers[0].position);
      setIptVal(0);
    });
    changeMarkerImg(0);
  }

  function makeLocations(list) {
    const arr = [];
    const length = list.length > 3 ? 3 : list.length;
    for (let i = 0; i < length; i++) {
      arr.push({
        lat:
          autocompleteLatLngdata.clickedModelCode != "ELW"
            ? parseFloat(list[i].position.lat)
            : parseFloat(list[i].contact.latitude),
        lng:
          autocompleteLatLngdata.clickedModelCode != "ELW"
            ? parseFloat(list[i].position.lng)
            : parseFloat(list[i].contact.longitude),
      });
    }
    console.log(autocompleteLatLngdata.clickedModelCode, arr);

    return arr;
  }

  function makeInfoBox(list) {
    let html = "";
    for (let i = 0; i < list.length; i++) {
      const one = list[i];
      const dc =
        autocompleteLatLngdata.clickedModelCode != "ELW"
          ? one.distance
          : one.distanceFromSearchedLocation;
      const distanceHTML = isEMEA
        ? `${(dc * 1.60934).toFixed(2)}KM`
        : `${dc}mi`;
      html +=
        autocompleteLatLngdata.clickedModelCode != "ELW"
          ? `<div class="googleMap__info-list ${
              i === 0 ? "active" : ""
            }"><div class="googleMap__img-box"><div class="googleMap__img-count">${
              i + 1
            }</div><p class="googleMap__distance">${distanceHTML}</p></div><div class="googleMap__detail-box"><p class="googleMap__detail-title">${
              one.name
            }</p><p class="googleMap__detail-address">${
              one.address.streetAddress
            }<br> ${one.address.city}<br> ${one.address.zipPost}<br> ${
              one.address.country
            }<br></p></div></div>`
          : `<div class="googleMap__info-list ${
              i === 0 ? "active" : ""
            }"><div class="googleMap__img-box"><div class="googleMap__img-count">${
              i + 1
            }</div><div class="googleMap__distance">${distanceHTML}</div></div><div class="googleMap__detail-box"><p class="googleMap__detail-title">${
              one.name
            }</p><p class="googleMap__detail-address">${
              one.contact.address1
            } <br> ${one.contact.postalCode} <br> ${
              one.contact.city
            }</p></div></div>`;
    }
    mapInfoListBox.empty().append(html);
  }

  mapInfoListBox.on("click", ".googleMap__info-list", (e) => {
    const list = $(".googleMap__info-list");
    const one = $(e.target).closest(".googleMap__info-list");
    list.removeClass("active");
    one.addClass("active");
    changeMarkerImg(one.index());
    setIptVal(one.index());
    map.panTo(markers[one.index()].position);
  });

  function initializeDealers(data) {
    for (let i = 0; i < (data.length > 3 ? 3 : data.length); i++) {
      allDealerInfo.push(data[i]);
    }
    dealerLocations = makeLocations(allDealerInfo);
    makeInfoBox(allDealerInfo);
    makeMarks(dealerLocations);
  }
  // clear marks
  function clearMarker() {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    markers = [];
  }
  // empty info dom
  function refreshMapInfo() {
    clearMarker();
    allDealerInfo = [];
    dealerLocations = [];
  }
  function mapInitialize() {
    const latlng = new google.maps.LatLng(51.477716, -0.189213);
    const mapOptions = {
      zoom: 9,
      center: latlng,
    };
    map = new google.maps.Map(document.getElementById("map"), mapOptions);
    mapContainer.show();
    mapContainer
      .parent(".formModel_row")
      .removeClass("formModel_map_container");
    if ($(window).width() < 900) {
      $("html,body").animate(
        {
          scrollTop: mapContainer.offset().top,
        },
        400,
        "swing"
      );
    }

    loader.css("visibility", "hidden");
    lcontainer.removeClass("formModel_row-last").removeClass("border-form");
  }

  // after get info from the google map, use lat and lng to get info from hd's url.
  function getDealersJson(url, data) {
    loader.css("visibility", "visible");
    refreshMapInfo();
    $.ajax({
      type: "post",
      url,
      data,
      success: (rs) => {
        if (rs.Status) {
          if (rs.Data && rs.Data.length) {
            mapInitialize();
            initializeDealers(rs.Data);
          } else {
            $("#searchTextField").val("").blur();
            loader.css("visibility", "hidden");
            lcontainer
              .removeClass("formModel_row-last")
              .removeClass("border-form");
            console.log("error");
          }
        } else {
          console.log(rs.Message);
        }
      },
    });
  }

  const notLimitedArea = ["AA", "XX", "EU", "ZZ", "AP", "IE"];
  function isLimited() {
    if (currentLocaleIso2.split(",").length > 1) {
      return false;
    }
    return notLimitedArea.indexOf(currentLocaleIso2) < 0;
    // return false;
  }

  let isLimitedBool = isLimited();

  const autocomplete = new google.maps.places.Autocomplete(dealerIpt);
  if (
    notLimitedArea.indexOf(currentLocaleIso2) < 0 ||
    currentLocaleIso2.split(",").length > 1
  ) {
    autocomplete.setComponentRestrictions({
      country: currentLocaleIso2.split(","),
    });
  }

  if ($("html").hasClass("en_AU")) {
    isLimitedBool = true;
    autocomplete.setComponentRestrictions({
      country: currentLocaleIso2.split(",")[0],
    });
    currentLocaleIso3 = originIso3.split(",")[0];
  }

  $("[data-dealer-location]").change((e) => {
    const originIos2 = currentLocaleIso2;
    isLimitedBool = true;
    if ($(e.target).val() === "au") {
      autocomplete.setComponentRestrictions({
        country: originIos2.split(",")[0],
      });
      currentLocaleIso3 = originIso3.split(",")[0];
    } else {
      autocomplete.setComponentRestrictions({
        country: originIos2.split(",")[1],
      });
      currentLocaleIso3 = originIso3.split(",")[1];
    }
  });
  function getError(err) {
    console.warn("ERROR(" + err.code + "): " + err.message);
  }
  function getSuccess(e) {
    getDealersJson(backEndUrl, {
      locale: currentLocaleIso3,
      lat: e.coords.latitude,
      lng: e.coords.longitude,
      limit: 10,
      isCurrent: isLimitedBool,
    });
  }

  const autocompleteLatLngdata = {
    inputLat: "",
    inputLng: "",
    clickedModelCode: "",
  };

  autocomplete.addListener("place_changed", () => {
    const places = autocomplete.getPlace();
    if (places.length === 0) {
      console.log("error");
      // do sth
      return;
    }
    autocompleteLatLngdata.inputLat = places.geometry.location.lat();
    autocompleteLatLngdata.inputLng = places.geometry.location.lng();
    const ajaxData = {
      modelCode: autocompleteLatLngdata.clickedModelCode,
      locale: currentLocaleIso3,
      lat: autocompleteLatLngdata.inputLat,
      lng: autocompleteLatLngdata.inputLng,
      limit: 10,
      isCurrent: isLimitedBool,
    };
    mapContainer.addClass("dn");
    getDealersJson(backEndUrl, ajaxData);
  });

  $("[data-urn]").click((e) => {
    const el = $(e.target).parents(".bikeList__card");
    autocompleteLatLngdata.clickedModelCode = el.attr("data-urn") || "";
    if (autocompleteLatLngdata.inputLat && autocompleteLatLngdata.inputLng) {
      mapContainer.addClass("dn");
      getDealersJson(backEndUrl, {
        modelCode: autocompleteLatLngdata.clickedModelCode,
        locale: currentLocaleIso3,
        lat: autocompleteLatLngdata.inputLat,
        lng: autocompleteLatLngdata.inputLng,
        limit: 10,
        isCurrent: isLimitedBool,
      });
    }
  });
});
