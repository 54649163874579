// Vendor
// import Jq from 'jquery';
import 'lodash';
import 'jquery-validation';
import 'jquery-datetimepicker';
import 'slick-carousel';
import 'jquery-lazyload';
// import fullpage from 'fullpage.js';
// Css bundle
import './index.scss';

// common JS module
import '../shared/js/util';
// Component JS modules:
import '../components/navigation/navigation';
import '../components/footer/footer';
import '../components/bikeList/bikeList';
import '../components/googleMap/googleMap';
import '../components/form/form';
import '../components/popupForForms/popupForForms';
import '../components/timeIpt/timeIpt';
// Pages
import '../pages/home/home';
/* eslint-disable */
window.jQuery = jQuery;
window._ = _;
/* eslint-enable */
