$(() => {
  if (!$(".formModel").length) {
    return;
  }

  const postCheckBox = $("#opt_in_mail");
  const phoneCheckBox = $("#opt_in_phone");
  const addContainer = $(".formModel-addr-container");
  const phoneContainer = $(".formModel-phone-container");

  postCheckBox.change((e) => {
    if ($(e.target).prop("checked")) {
      addContainer.show();
    } else {
      addContainer.hide();
    }
  });
  phoneCheckBox.change((e) => {
    if ($(e.target).prop("checked")) {
      phoneContainer.show();
    } else {
      phoneContainer.hide();
    }
  });
  $(".js-formbuilder_link_overlay").on("click", (e) => {
    e.preventDefault();
    e.stopPropagation();
    const target = $(e.target).attr("href");
    console.log(target);
    const offset =
      $(".js-formbuilder_link_overlay_drop").offset().top -
      $(".js-formbuilder_link_overlay_drop")
        .parent(".formModel-body-text")
        .offset().top;
    $(e.target).addClass("formbuilder_link_overlay");
    const top = 28 + offset;
    $(".formModel-overlay").css("top", top);
    if (target === "#slide1") {
      $("#slide2").addClass("no-show");
      $("#slide1").removeClass("no-show");
    } else {
      $("#slide1").addClass("no-show");
      $("#slide2").removeClass("no-show");
    }
  });
  $("body").on("click", function (e) {
    if (!$(e.target).hasClass("js-formbuilder_link_overlay")) {
      $(".formModel-overlay").css("top", "-99999px");
      $(".js-formbuilder_link_overlay").removeClass("formbuilder_link_overlay");
    }
  });

  const serviceCheckBox = $("[data-show-service]");
  const serviceBox = $("[data-service-box]");
  serviceCheckBox.change((e) => {
    if ($(e.target).prop("checked")) {
      serviceBox.removeClass("dn");
    } else {
      serviceBox.addClass("dn");
    }
  });

  const saleForceItem = $("[data-sError]");
  if (saleForceItem.html()) {
    saleForceItem.show();
  } else {
    saleForceItem.hide();
  }
});
