$(() => {
  const infoBoxClass = ['first', 'second', 'third', 'forth'];
  const selectBtn = $('[data-select-btn]');
  const selectUl = $('[data-select-ul]');
  const selectLi = selectUl.find('li');
  const card = $('[data-bike-card]');
  const cardContainer = $('[data-bike-container]');
  const bikeIpt = $('[data-bike-ipt]');

  function selectFn(all, idx) {
    all.removeClass('active');
    all.eq(idx).addClass('active');
    selectBtn.find('span').html(all.eq(idx).find('span').html());
  }

  function chooseLast(box, num) {
    let last = 0;
    if (box.eq(num).length) {
      last = num;
    } else {
      for (let i = num; i--;) {
        if (box.eq(i).length) {
          last = i;
          break;
        }
      }
    }
    return last;
  }

  const findDealerCopy = $('#findDealerCopy').val();

  function creatInfoBox(item, all) {
    if ($('.bikeList__info').length) {
      $('.bikeList__info').remove();
    }
    item.siblings('.bikeList__card').removeClass('active').addClass('others');
    item.addClass('active');
    const ex = $(window).width() >= 943 ? 4 : 2;
    let last = 0;
    const idx = item.index();
    const infoJson = JSON.parse(item.attr('data-info-json'));
    const html = `<div class="bikeList__info ${infoBoxClass[idx % ex]}"><img class="bikeList__info-img" src="${infoJson.infoSrc}" width="174" /><div class="bikeList__info-box"><div class="bikeList__info-close"><svg class="icon icon-close"><use xlink:href="#icon-close"></use></svg></div><div class="bikeList__info-title">${infoJson.title}</div><div class="bikeList__info-text">${infoJson.des}</div><div class="bikeList__info-btn"><div class="common-cta_btn bikeList__findDealer" href="${infoJson.link}">${findDealerCopy}<svg class="icon icon-arrow-right"><use xlink:href="#icon-arrow-right"></use></svg></div></div></div></div>`;
    if (ex === 2) {
      last = idx % ex === 0 ? idx + 1 : idx;
    } else {
      last = idx % 4 === 3 ? idx : idx % 4 === 2 ? idx + 1 : idx % 4 === 1 ? idx + 2 : idx + 3;
    }

    all.eq(chooseLast(card, last)).after(html);
    bikeIpt.val(item.attr('data-bike-id'));
  }

  function setSameHeight(items) {
    window.Util.setSameHeight(items);
  }

  card.click((e) => {
    const eTarget = $(e.target);
    if (eTarget.hasClass('active')) {
      const currentInfo = $('.bikeList__info');
      currentInfo.hide();
      eTarget.removeClass('active');
      card.removeClass('others');
      bikeIpt.val('');
    } else {
      creatInfoBox(eTarget, card);
      selectFn(selectLi, eTarget.index());
    }
  });

  setSameHeight(card);

  $(window).on('resize', window._.debounce(() => {
    setSameHeight(card);
  }, 200));

  cardContainer.click((e) => {
    const et = $(e.target);
    if (et.hasClass('bikeList__info-close')) {
      et.parents('.bikeList__info').hide();
      card.removeClass('active others');
    }
  });

  selectBtn.click(() => {
    selectBtn.toggleClass('open');
    selectUl.toggleClass('open');
  });

  selectLi.click((e) => {
    const et = $(e.target);
    selectFn(selectLi, et.index());
    creatInfoBox(card.eq(et.index()), card);
    selectBtn.removeClass('open');
    selectUl.removeClass('open');
  });
});
