$(() => {
  const bookingForm = $('[data-booking-form]');
  const findDealerOut = $('[data-bike-container]');
  const homeMap = $('.home__map');
  bookingForm.validate(window.Util.validateThings);
  bookingForm.on('submit', (e) => {
    if (!$('input[name="bikeId"]').val().length || !$('[data-dealer-id]').val().length || !window.Util.timeValidation) {
      e.preventDefault();
      e.stopPropagation();
      if (!window.Util.timeValidation) {
        $('input[name="birthDay"]').focus();
      }
      const currentPoint = !$('input[name="bikeId"]').val().length ?
        $('.bikeList').offset().top : !$('[data-dealer-id]').val().length ? $('.googleMap').offset().top : $('.common_sections.form_error').offset().top;
      $('html, body').animate({
        scrollTop: currentPoint - 20,
      }, 500);
    }

    let result = bookingForm.validate(window.Util.validateThings);
    if (!result.errorList.length) {
      bookingForm.find('button.common-cta_btn').attr('disabled', 'disabled').addClass('disabledType');
      $('[data-form-loader]').show();
    }
  });

  findDealerOut.click((e) => {
    if ($(e.target).hasClass('bikeList__findDealer')) {
      $('html, body').animate({
        scrollTop: homeMap.offset().top - 25,
      }, 500);
    }
  });

  // (function(){
  //   const oldUrl = window.location.href; //'https://rentals.harley-davidson.com/#/step-1?v=TSD&locale=en_GB&pickuploc=198070';
  //   const oldLocale = oldUrl.substr(oldUrl.indexOf('locale') + 7, 5);
  //   const newUrl = oldUrl.replace(/\#\/step\-1/g, oldLocale);
  //   window.location.href = newUrl;
  // }(window));

  if ($('html').hasClass('de_AT') || $('html').hasClass('de_DE') || $('html').hasClass('de_CH')) {
    $('#haveLicense').parents('.common_sections').addClass('twoLine');
  }

  const contents = $('.pageLoad');

  function scrollClass(el, className) {
    const distance = el.offset().top;
    const scrollTop = $(window).scrollTop();
    const winHeight = $(window).height();

    if (scrollTop + winHeight > distance) {
      el.removeClass(className);
    }
  }

  $(window).scroll(() => {
    contents.map((idx, item) => {
      scrollClass($(item), 'pageLoad');
    });
  });


});
